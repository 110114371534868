
import SegmentUploadHeader from "@/views/Admin/Segments/SegmentsUpload/SegmentsUploadHeader.vue";
import Vue from "vue";

export default Vue.extend({
    name: "SegmentsUpload",
    props: {},
    components: { SegmentUploadHeader },
    data: () => ({}),
    created() {},
    async mounted() {},
    computed: {},
    methods: {},
});	
